import React, { useEffect, useRef } from "react";
import "./company.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Navbar from "../landing/header/Navbar";
import Lottie from "lottie-web";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import Footer from "../landing/footer/Footer";
import { Link } from "react-router-dom";
gsap.registerPlugin(ScrollTrigger);


const Company = () => {


  const containerRef = useRef(null);

  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: containerRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/circular-animation.json',
    });

    return () => {
      animation.destroy();
    };
  }, []);

  const headingRef = useRef(null);

  useEffect(() => {
    const animateText = (ref, speed) => {
      const chars = ref.current.innerText.split('');


      ref.current.innerHTML = '';


      chars.forEach(char => {
        const charSpan = document.createElement('span');
        charSpan.innerText = char;
        ref.current.appendChild(charSpan);
      });


      gsap.from(ref.current.children, {
        y: 100,
        opacity: 0.2,
        stagger: 0.1,
        duration: speed
      });

      gsap.to(ref.current.children, {
        color: '#fff',
        stagger: 0.1,
        duration: speed
      });
    };

    animateText(headingRef, 0.1);
    // animateText(btn1Ref, 0.1);  
  }, []);


  // different examples of 
  // const headingRef1 = useRef(null);
  // const headingRef2 = useRef(null);
  const paragraphRef1 = useRef(null);
  // const paragraphRef2 = useRef(null);

  useEffect(() => {
    const animateText = (ref, speed, animationType) => {
      switch (animationType) {
        case 'fadeIn':
          gsap.from(ref.current, {
            opacity: 0,
            duration: speed
          });
          break;
        case 'slideUp':
          gsap.from(ref.current, {
            y: 100,
            opacity: 0,
            duration: speed
          });
          break;
        case 'scale':
          gsap.from(ref.current, {
            scale: 0,
            opacity: 0,
            duration: speed
          });
          break;
        default:
          break;
      }
    };

    // animateText(headingRef1, 0.5, 'fadeIn');
    // animateText(headingRef2, 1, 'slideUp');
    animateText(paragraphRef1, 2, 'scale');
    // animateText(paragraphRef2, 5, 'fadeIn');
  }, []);



  const btnRef = useRef(null);

  useEffect(() => {
    const animateText = (ref, speed) => {
      const chars = ref.current.innerText.split('');

      ref.current.innerHTML = '';
      chars.forEach(char => {
        const charSpan = document.createElement('span');
        charSpan.innerText = char;
        ref.current.appendChild(charSpan);
      });

      gsap.from(ref.current.children, {
        y: 100,
        opacity: 0.2,
        stagger: 0.03,
        duration: speed
      });

      gsap.to(ref.current.children, {
        color: '#fff',
        stagger: 0.03,
        duration: speed
      });
    };

    const btn = btnRef.current;

    const handleMouseEnter = () => {
      animateText(btnRef, 0.2);
    };

    const handleMouseLeave = () => {
      btnRef.current.innerHTML = btn.innerText;
    };

    btn.addEventListener('mouseenter', handleMouseEnter);
    btn.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      btn.removeEventListener('mouseenter', handleMouseEnter);
      btn.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);



  const firstSectionRef = useRef(null);
  const secondSectionRef = useRef(null);


  useEffect(() => {
    const isLargeScreen = window.matchMedia("(min-width: 1500px)").matches;
    const isMobileScreen = window.matchMedia("(max-width: 600px)").matches;

    gsap.to(firstSectionRef.current, {
      scrollTrigger: {
        trigger: secondSectionRef.current,
        start: isMobileScreen ? 'top 40%' : (isLargeScreen ? 'top 35%' : 'top 40%'),
        end: isMobileScreen ? 'bottom top' : (isLargeScreen ? 'bottom top' : 'bottom top'),
        scrub: true,
      },
      filter: 'blur(40px)',
    });
  }, []);



  const wrapperRefSecond = useRef(null);

  useEffect(() => {
    const wrapper = wrapperRefSecond.current;

    const cloneAndAppendImages = () => {
      const images = wrapper.querySelectorAll('.communitycard');
      const totalWidth = Array.from(images).reduce((acc, img) => acc + img.offsetWidth, 0);
      const clonedImages = Array.from(images).map(img => img.cloneNode(true));

      clonedImages.forEach(img => wrapper.appendChild(img));
      wrapper.style.width = `${totalWidth * 4}px`;
    };

    const animateScroll = () => {
      gsap.to(wrapper, {
        x: -wrapper.offsetWidth / 2,
        duration: wrapper.offsetWidth / 100,
        ease: 'linear',
        onComplete: () => {
          gsap.set(wrapper, { x: 0 });
          animateScroll();
        }
      });
    };

    cloneAndAppendImages();
    animateScroll();

    return () => {

    };
  }, []);



  return (
    <>
      <Navbar />
      <section className="maincompany" ref={firstSectionRef}>
        <div className="custom-container">
          <div className="techbannerinner">
            <h1 className="techhead" ref={paragraphRef1}> <span ref={headingRef}>THE COMPANY</span> </h1>
          </div>
        </div>
      </section>
      <section className="theteam" ref={secondSectionRef}>
        <div className="top-left-text">
          <p>01. The Company</p>
        </div>
        <div className="custom-container ">
          <div className="parenttext">
            <div className="left">
              <p>
              Founded in 2022, Data Ownership Protocol (DOP) is a global blockchain trailblazer that redefines and expands upon the original concepts of data ownership. With offices in Japan, Pakistan, and Tel Aviv, our team spans the globe, including vibrant hubs like Dubai, Portugal, and Poland.
              </p>
            </div>
            <div className="right">
              <p>
              DOP fosters connections with global blockchain communities and crypto enthusiasts. In just over a month and a half, our company has grown by 30%, and over two years, we have sponsored global events such as the 2023 Binance Campus in Ibiza, BBW2023 in Turkey, and Token2049 in Dubai.
              </p>
            </div>
          </div>
          <div className="parenttexts">
            <div className="left">
              <p>
              Join us in revolutionizing the blockchain landscape. Whether you're an enthusiast, developer, or entrepreneur, DOP  invites you to be part of our journey.
              
              </p>
              <br />
              <p>Connect today to explore career opportunities.</p>
                <Link to="/contact" className="btn-launch contactus-btn">
                  <h6 ref={btnRef}>Contact Us</h6>
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                    <path d="M9.20831 6.16665L12.0416 8.99998L9.20831 11.8333M4.95831 6.16665L7.79165 8.99998L4.95831 11.8333" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </Link>
            </div>
            <div className="right">
              <div className="mainimg">
                <img
                  src="\assets\Company\mainimage.svg"
                  alt="img"
                  className="img-fluid images"
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ overflow: "hidden" }}>
          <div className="communitymaincards" ref={wrapperRefSecond}>
            <div className="communitycard">
              <div className="communitycardimg">
                <img src="https://res.cloudinary.com/drt6vurtt/image/upload/v1715545048/dop/dopcommunity/selman1974T01_lg4exg.jpg" alt="communitycardimg" className="communitycardimg imgfive" />
              </div>
              <h6 className="communitycardhead">Activity Title</h6>
            </div>
            <div className="communitycard">
              <div className="communitycardimg">
                <img src="https://res.cloudinary.com/drt6vurtt/image/upload/v1715545048/dop/dopcommunity/selman2127T01_s0uqdu.jpg" alt="communitycardimg" className="communitycardimg imgfive" />
              </div>
              <h6 className="communitycardhead">Activity Title</h6>
            </div>
            <div className="communitycard">
              <div className="communitycardimg">
                <img src="https://res.cloudinary.com/drt6vurtt/image/upload/v1715545048/dop/dopcommunity/Copy_of_photo_2023-10-17_10-43-41_v9osxk.jpg" alt="communitycardimg" className="communitycardimg imgfive" />
              </div>
              <h6 className="communitycardhead">Activity Title</h6>
            </div>
            <div className="communitycard">
              <div className="communitycardimg">
                <img src="https://res.cloudinary.com/drt6vurtt/image/upload/v1715545054/dop/dopcommunity/DSC01312_igkcln.jpg" alt="communitycardimg" className="communitycardimg imgfive" />
              </div>
              <h6 className="communitycardhead">Activity Title</h6>
            </div>
            {/* <div className="communitycard">
              <div className="communitycardimg">
                <img src="https://res.cloudinary.com/drt6vurtt/image/upload/v1715545057/dop/dopcommunity/DSC01549_hwivje.jpg" alt="communitycardimg" className="communitycardimg imgfour" />
              </div>
              <h6 className="communitycardhead">Activity Title</h6>
            </div> */}
            <div className="communitycard">
              <div className="communitycardimg">
                <img src="https://res.cloudinary.com/drt6vurtt/image/upload/v1715545059/dop/dopcommunity/DSC02607_ghquo4.jpg" alt="communitycardimg" className="communitycardimg imgthree" />
              </div>
              <h6 className="communitycardhead">Activity Title</h6>
            </div>
            {/* <div className="communitycard">
              <div className="communitycardimg">
                <img src="https://res.cloudinary.com/drt6vurtt/image/upload/v1715545062/dop/dopcommunity/DSC01498_zewqww.jpg" alt="communitycardimg" className="communitycardimg imgtwo" />
              </div>
              <h6 className="communitycardhead">Activity Title</h6>
            </div> */}
            <div className="communitycard">
              <div className="communitycardimg">
                <img src="https://res.cloudinary.com/drt6vurtt/image/upload/v1715545066/dop/dopcommunity/DSC00913_bjbscv.jpg" alt="communitycardimg" className="communitycardimg imgone" />
              </div>
              <h6 className="communitycardhead">Activity Title</h6>
            </div>

          </div>
        </div>
        <div className="custom-container">
          <div className="parenttext">
            <div className="left">
              <p>
              What sets us apart is our commitment to interoperability, regulatory compliance and future proofing. We're not building isolated islands in the crypto sea.
              </p>
            </div>
            <div className="right">
              <p>
              DOP is designed to seamlessly integrate with existing dApps, ensuring a safe, familiar and intuitive user experience. Our technology ensures seamless connectivity with the external ecosystem, unlocking new possibilities that were previously out of reach.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="joinus">
        {/* <img src="\assets\tech\joinussvg.svg" alt="joinussvg" className="joinussvg" /> */}
        <div className="testimonial-lottie-1 is-margin-bottom stats-bg joinussvg" ref={containerRef}></div>
        <div className="custom-container">
          <div className="mainheadingsss">
            <h2 className="joinhead">
              Join us at DOP as we pave the way for mainstream adoption in the
              web3 realm
            </h2>
            <Link to="/contact" className="btn-launch">
              <h6 ref={btnRef}>Get In Touch</h6>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
              >
                <path
                  d="M9.20831 6.16665L12.0416 8.99998L9.20831 11.8333M4.95831 6.16665L7.79165 8.99998L4.95831 11.8333"
                  stroke="white"
                  stroke-width="1.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Company;
